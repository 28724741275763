.features {
    position: relative;
    overflow: hidden;
    transition: all 0.2s ease;

    .feature-grid {
        @apply max-w-[100%] lg:max-w-[80%] mx-auto;
        opacity: 1;

        .feature-heading {
            @apply text-4xl lg:text-4xl text-white mb-2 lg:mb-8;
            opacity: 1;
        }
    }
    

    .ton-card {
        @apply bg-[#fff] rounded-sm w-full lg:max-w-[500px] z-10 py-8;
        opacity: 1;
        transition: background 300ms ease-in-out;

        .card-banner {
            @apply relative flex justify-center items-center flex-col overflow-hidden h-[300px];
            opacity: 1;

            img {
                @apply relative z-10 max-w-[250px] md:max-w-[300px];
                opacity: 1;
            }

        }

        .card-content {
            @apply p-8 text-center;
            opacity: 1;

            h4 {
                @apply text-2xl mb-4 font-bold text-[#333];
                opacity: 1;
            }

            p {
                @apply text-xl text-left text-[#333];
                opacity: 1;
            }
        }

        &:hover {
            .card-content {
                h4 {
                    @apply text-[#fff];
                    opacity: 1;
                }
                p {
                    @apply text-[#fff];
                    opacity: 1;
                }
            }
        }
    }

    .ton-card-wrapper {
        background: rgb(128 128 128 / 0.2);
        border-radius: 24px;
        position: relative;
        z-index: 10;
        overflow: hidden;

        .blob {
            filter: blur(40px);
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 300px;
            height: 300px;
            border-radius: 50%;
            background: rgba(245, 5, 5, 0.993);
            transform: translate(var(--circle-x, 0), var(--circle-y, 0));
            transition: transform 0.1s;
            pointer-events: none;
            opacity: 0;
        }

        .fakeblob {
            display: hidden;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 300px;
            height: 300px;
            border-radius: 50%;
        }

        &:hover {
            .blob {
                opacity: 1;
            }

            .ton-card.first {
                background: #4989A7;
                backdrop-filter: blur(80px);
            }

            .ton-card.second {
                background: #1C6385;
                backdrop-filter: blur(80px);
            }

            .ton-card.third {
                background: #204B60;
                backdrop-filter: blur(80px);
            }
        }
    }
}

.new-features {
    .acc-card {
        &.first {
            @apply bg-[#4989A7] rounded-t-lg;
            opacity: 1;
        }

        &.second {
            @apply bg-[#1C6385];
            opacity: 1;
        }

        &.third {
            @apply bg-[#204B60] rounded-b-lg;
            opacity: 1;
        }
    }
}