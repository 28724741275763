*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SF Pro Display', sans-serif;
}

button {
  border: none;
  outline: none;
  transition: all 0.3s ease;
}