.ton {
    overflow: visible;
    transition: all 0.2s ease;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ton-card-wrapper {
        position: relative;
        z-index: 10;
        overflow: hidden;
        background-color: antiquewhite;

        .logo-parent {
            background-color: transparent;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: repeat(4, 1fr);
            border: 20px solid #F4FCFF;

            &.white {
                border: 20px solid #4989A7;
            }
        }

        .logo-parent>div {
            background-color: #F4FCFF;
            padding: 50px;
        }

        .logo-parent.white>div {
            background-color: #4989A7;
            padding: 50px;
        }

        .transparent {
            background-color: transparent;
        }

        /* Applying transparency to specific blocks */
        .logo-parent>div:nth-child(3),
        .logo-parent>div:nth-child(4),
        .logo-parent>div:nth-child(7),
        .logo-parent>div:nth-child(8),
        .logo-parent>div:nth-child(10),
        .logo-parent>div:nth-child(11),
        .logo-parent>div:nth-child(13),
        .logo-parent>div:nth-child(14),
        .logo-parent>div:nth-child(16),
        .logo-parent>div:nth-child(17),
        .logo-parent>div:nth-child(18) {
            background-color: transparent;
        }
    }

    &:hover {
        .blob {
            animation: none;
        }
    }
}

.blob {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 2000px;
    height: 2000px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    transition: transform 0.1s;
    transform: translate(var(--circle-x, -1000px), var(--circle-y, -1300px)) rotate(180deg);
    pointer-events: none;
    animation: evolve 10s infinite;

    img {
        transition: all 0.3s ease;
    }
}

@keyframes evolve {
    0% {
        transform: translateX(-1000px) translateY(-1000px) rotate(0);
    }

    100% {
        transform: translateX(-1000px) translateY(-1000px) rotate(360deg);
    }
}

@media (max-width: 1024px) {
    .ton{
        .ton-card-wrapper {
            .logo-parent>div {
                padding: 30px;
            }

            .logo-parent.white>div {
                padding: 30px;
            }
        }
    }
}