.infrastructure-card {
    @apply w-full min-h-[500px] mb-20 lg:mb-0;
    opacity: 1;

    .content,
    .content button,
    .image .overlay {
        transition: all 1s ease;
    }

    .content {
        @apply p-6 text-white;
        opacity: 1;


        span {
            font-family: "IBM Plex Mono", monospace;
        }

        h3 {
            @apply text-5xl mb-6;
            opacity: 1;
        }

        p {
            @apply text-xl;
            opacity: 1;
        }

        button {
            @apply border-none;
            opacity: 1;
        }
    }


    .image {
        @apply bg-cover bg-center w-full h-full bg-dark relative overflow-hidden;
        opacity: 1;

        .overlay {
            @apply absolute top-0 left-0 w-full h-full;
            opacity: 1;
        }
    }

    &.join {
        @apply flex flex-col lg:flex-row;
        opacity: 1;

        .content {
            @apply lg:max-w-[35%] flex flex-col justify-between bg-[#7fa0af] rounded-t-xl lg:rounded-s-xl lg:rounded-tr-none;
            opacity: 1;

            p {
                @apply mb-10 lg:mb-0;
                opacity: 1;
            }

            button {
                @apply text-[#7FA0AF];
                opacity: 1;
            }
        }

        .image {
            @apply rounded-b-xl lg:rounded-e-xl lg:rounded-bl-none h-[300px] lg:h-full;
            background-image: url("../../../../assets/Office-Space.png");

           

            .overlay {
                @apply bg-[#7fa0af48];
                opacity: 1;
            }
        }
    }



    &:hover {
        @apply cursor-pointer;
        opacity: 1;

        .content {
            @apply bg-[#4989A7];
            opacity: 1;

            button {
                @apply text-[#4989A7];
                opacity: 1;
            }
        }

        .image {
            .overlay {
                @apply bg-[#4989a768];
                opacity: 1;
            }
        }
    }
}
